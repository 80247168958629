<template>
    <div class="d-flex">
        <div class="pa-4 mb-2">
            <MessageContent :content="content" />
        </div>
    </div>
</template>

<script>
import MessageContent from "@/components/MessageContent";

export default {
    name: 'ChatLenaMessage',
    components: { MessageContent },
    props: {
        content: {
            type: String,
            required: true,
        }
    },
}
</script>
