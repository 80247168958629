<template>
    <div class="d-flex flex-row-reverse">
        <v-chip color="grey white--text darken-3" class="pa-4 mb-2">
            {{ content }}
        </v-chip>
    </div>
</template>

<script>
export default {
    name: 'ChatUserMessage',
    props: {
        content: {
            type: String,
            required: true,
        }
    },
}
</script>
