import axios from "axios";

import { Buffer } from "buffer";

const {
  VUE_APP_YUNA_API_BASE_URL: baseUrl,
} = process.env;

export const useYunaClient = () => {
  return axios.create({
    baseURL: baseUrl,
    timeout: 60000,
  });
};

export async function createKeyManager(secret) {
  const encoder = new TextEncoder("utf-8");

  const secretEncoded = Buffer.from(secret, "base64");
  const key = await crypto.subtle.importKey("raw", secretEncoded, {
    name: "HMAC", hash: { name: "SHA-256" }
  }, false, ["sign", "verify"]);

  const sign = async (content) => {
    const data = encoder.encode(content);
    const signature = await crypto.subtle.sign("HMAC", key, data);
    return Buffer.from(signature).toString("hex");
  };
  const verify = async (content, signature) => {
    signature = Buffer.from(signature, "hex");
    const data = encoder.encode(content);
    return await crypto.subtle.verify("HMAC", key, signature, data);
  };

  return { sign, verify };
}
