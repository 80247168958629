<template>
    <div class="text-sm pisces-content" v-html="contentRender"></div>
</template>

<script>
import marked from 'marked';
import DOMPurify from "dompurify";

export default {
    name: "MessageContent",
    props: {
        content: {
            type: String,
            required: true,
        },
    },
    computed: {
        contentRender() {
            let render = this.content;
            render = marked(render);
            render = DOMPurify.sanitize(render);
            return render;
        },
    },
};
</script>
