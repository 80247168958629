<template>
    <v-container class="fill-height pa-0 ">
        <v-row class="fill-height no-gutters">
            <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
                <v-responsive class="overflow-y-hidden fill-height">
                    <v-card flat class="d-flex flex-column fill-height">
                        <v-card-subtitle class="grey--text">
                            "Pisces Kernel" might make mistakes or lie to you.
                            Consider checking important information.
                        </v-card-subtitle>
                        <v-card-text class="flex-grow-1 overflow-y-auto">
                            <div v-for="(i, j) in messages" :key="j">
                                <ChatUserMessage :content="i.content" :createdTime="i.createdTime" v-if="i.isUser" />
                                <ChatLenaMessage :content="i.content" :createdTime="i.createdTime" v-else />
                            </div>
                        </v-card-text>
                        <v-card-text class="red--text" v-if="isError">
                            Internal errors occurred.
                            The conversation has been interrupted.
                        </v-card-text>
                        <div ref="messageLast"></div>
                        <v-card-text class="flex-shrink-1">
                            <div class="no-gutters" v-show="!isReady && !isError">
                                <v-progress-linear color="grey accent-4" indeterminate rounded height="6" />
                            </div>
                            <v-text-field v-model="userInput" ref="messageInput" placeholder="Type your message…"
                                color="grey" type="text" no-details hide-details append-outer-icon="mdi-send"
                                @keydown.enter="onClickSend" @click:append-outer="onClickSend" />
                        </v-card-text>
                    </v-card>
                </v-responsive>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import dayjs from "dayjs";

import ChatUserMessage from "../components/ChatUserMessage.vue";
import ChatLenaMessage from "../components/ChatLenaMessage.vue";

import { useYunaClient, createKeyManager } from "../plugins/yuna";

export default {
    name: "LenaView",
    components: {
        ChatUserMessage,
        ChatLenaMessage,
    },
    data() {
        return {
            sessionId: "",
            sessionSecret: "",
            sessonKeyManager: null,
            isReady: false,
            isError: false,
            userInput: "",
            messages: [],
            localeCode: navigator.language || navigator.userLanguage,
        };
    },
    computed: {
        client() {
            return useYunaClient();
        },
        displayName() {
            return this.$store.state.nickname || "master";
        }
    },
    methods: {
        focusInput() {
            requestAnimationFrame(() => {
                this.$refs.messageInput.focus();
            });
        },
        scrollToBottom() {
            requestAnimationFrame(() => {
                this.$refs.messageLast.scrollIntoView({
                    behavior: "smooth",
                });
            });
        },
        addMessageHistory({ isUser, content }) {
            this.messages.push({
                isUser,
                content,
                createdTime: dayjs(),
            });
        },
        async sendMessage(displayName, inputContent, localeCode) {
            this.isReady = false;
            try {
                const { data: {
                    sessionId: outputSessionId,
                    sessionKey: outputSessionKey,
                    content: outputContent,
                } } = await this.client.post("chat/message", {
                    sessionId: this.sessionId,
                    sessionKey: await this.sessonKeyManager.sign([
                        displayName,
                        inputContent,
                        localeCode,
                    ].join("\x1e")),
                    displayName,
                    content: inputContent,
                    locale: localeCode,
                });
                if (outputSessionId !== this.sessionId) {
                    throw Error();
                }
                if (!await this.sessonKeyManager.verify([
                    displayName,
                    outputContent,
                    localeCode,
                ].join("\x1e"), outputSessionKey)) {
                    throw Error();
                }
                this.addMessageHistory({
                    isUser: false,
                    content: outputContent,
                });
                this.isReady = true;
                this.focusInput();
            } catch (e) {
                console.error(e.message);
                this.isError = true;
            }
            this.scrollToBottom();
        },
        onClickSend() {
            if (!this.userInput) {
                return;
            }
            this.addMessageHistory({
                isUser: true,
                content: this.userInput,
            });
            this.sendMessage(
                this.displayName,
                this.userInput,
                this.localeCode,
            );
            this.userInput = "";
            this.scrollToBottom();
        },
    },
    async mounted() {
        const { data: {
            sessionId: id,
            sessionSecret: secret
        } } = await this.client.get("chat/session")

        this.sessionId = id;
        this.sessionSecret = secret;
        this.sessonKeyManager = await createKeyManager(secret);

        let initInput = "Hello! My friend.";
        if ("q" in this.$route.query) {
            initInput = this.$route.query.q;
            this.addMessageHistory({
                isUser: true,
                content: initInput,
            });
        }
        await this.sendMessage(
            this.displayName,
            initInput,
            this.localeCode,
        );
    },
};
</script>
